<template>
    <div>
        <div class="all_container">
            <div class="container">
                <!-- 首页切换 -->
                <div class="changeClassify">
                    <div class="title" @click="gohome">{{ $t('other.homePage') }}</div>
                    <div>-</div>
                    <div>{{ lang == 'zh' ? zh_name : bnm_name }}</div>
                </div>
                <div class="carousel">
                    <!-- 中间的轮播图 -->
                    <div class="carousel_right">
                        <el-carousel trigger="click" height="450px" arrow="always">
                            <el-carousel-item v-for="(item, index) in bannerArr1" :key="index">
                                <img :src="item.thumb" class="big_img" @click="bannerArrChange(item)">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
                <!-- 经纪人分类区 -->
                <div class="main_body">
                    <!-- 经纪人分类 -->
                    <div class="info" style="padding: 88px 0 0 0;">
                        <div class="title">
                            <div>{{ $t('broker') }}</div>
                            <div class="border"></div>
                        </div>
                        <div class="classify">
                            <div class="top">
                                <div class="top_box" v-for="(item, index) in classify" :key="index"
                                    @click="goClassify(item)">
                                    <div class="img">
                                        <img :src="item.thumb" alt="">
                                    </div>
                                    <div class="top_box_border">
                                        <div class="title">{{ lang == 'zh' ? item.name : item.sp_name }}</div>
                                    </div>

                                </div>
                            </div>
                            <!-- <div class="bottom">
                            </div> -->
                        </div>
                    </div>

                    <!-- 热门经纪人 -->
                    <div class="info" style="padding-top:88px;">
                        <div class="title">
                            <div>{{ $t('home.hotBroker') }}</div>
                            <div class="border"></div>
                        </div>
                        <div class="broker_box">
                            <div class="info_box" v-for="(item, index) in borkerList" :key="index" >
                                <div>
                                    <div class="broker_img" :style="'background-image:url('+ item.company_logo +');'">
                                        <div class="broker_img_m" ></div>
                                        <div class="border_info">
                                            <div class="border_info_name">{{ item.company}}</div>
                                            <div class="border"></div>
                                            <div class="border_info_content">
                                                {{ item.company_desc }}
                                            </div>

                                        </div>
                                    </div>


                                    <div class="talent_agency">
                                        <div class="left">
                                            <img :src="item.head_img" alt="">
                                        </div>
                                        <div class="right">
                                            <div class="name">
                                                {{ item.user_nickname}}
                                            </div>
                                            <div class="job">
                                                {{ lang == 'zh' ? item.type_name : item.sp_name }}
                                            </div>
                                            <div class="num">
                                                {{$t('brokerLicenseNumber')}}：{{ item.license_number }}
                                            </div>
                                            <div class="address">
                                                <img src="@/assets/new_home_icons/address_icon.png" alt="">
                                                {{ item.adress }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="btn" @click="goBroker(item)">
                                        {{ $t('consultationDetails') }}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>




                    <!-- <div class="info" style="padding: 88px 0 0 0; padding-top: 88px;">
                        <div class="title" style="margin-bottom: ;">
                            <div>房地产公司</div>
                            <div class="border"></div>
                        </div>
                        <div class="company">
                            <div class="company_box">
                                <img src="@/assets/new_home_icons/tuniu.png" alt="">
                            </div>
                        </div>
                    </div> -->


                </div>

            </div>
        </div>
        <img src="@/assets/loading.gif" class="loadingImg" v-if="loadingValue == true">
    </div>
</template>

<script>
import houseItem from '@/components/houseItem.vue';

export default {
    components: {
        houseItem,
    },
    data() {
        return {
            classifyStatus: '',
            recommendList: [], //热门推荐列表
            likeList: [], //喜欢列表

            total: 8, //总条数
            currentPage: 1, //当前页数
            pageSize: 16, //每页的条数
            pageSizes: [16],

            bannerArr1: [], //轮播图1
            bannerArr2: [],
            bannerArr3: [],
            bannerArr4: [],
            bannerArr5: [],
            bannerArr6: [],
            bannerArr7: [],


            house_classify: [],
            classify: [],
            car_classify: [],

            borkerList: [],

            zh_name:'',
            bnm_name:'',


            lang: '',
            vip: '',
            loadingValue: false,
        }
    },
    created() {
        this.pid = this.$route.query.pid
        this.zh_name = this.$route.query.zh_name
        this.bnm_name = this.$route.query.bnm_name

        this.lang = localStorage.getItem('lang');
        this.getRecommend();
        this.getAllLike();
        this.getAds(1, 3);
        // this.getAds(2, 4);
        // this.getAds(3, 5);
        // this.getAds(4, 6);
        // this.getAds(5, 7);
        // this.getAds(6, 8);
        // this.getAds(7, 9);

        // this.getCateList()

        this.getType()
        this.getBorkerList()


        // 实现监听localstorage中某个键对应的值的变化
        //根据自己需要来监听对应的key
        window.addEventListener("setItemEvent", (e) => {
            //e.key : 是值发生变化的key
            //例如 e.key==="token";
            //e.newValue : 是可以对应的新值
            if (e.key === "lang") {
                this.lang = e.newValue;
            }
        })

        this.getUserInfo();
    },

    mounted() {
    },
    methods: {
        gohome() {
            this.classifyStatus = 3
            this.$emit('chaangeClassifyStatus', this.classifyStatus)
        },
        // 跳转到经纪人分类页
        goClassify(item) {
            console.log(item);
            this.$router.push(
                {
                    path: `/broker_classify_leve2`,
                    query: {
                        classify_id: item.id,
                        zh_name: item.name,
                        bnm_name: item.sp_name,
                        father_zh_name:this.zh_name,
                        father_bnm_name:this.bnm_name,
                        pid:item.pid
                    }
                }
            )
        },

        goBroker(item){
            this.$router.push(
                {
                    path: `/borker_details`,
                    query: {
                        id: item.user_id,
                    }
                }
            )
        },


        // 获取代理类型分类
        getType() {
			this.$http.getType().then(res => {
				if (res.code == 1) {
					console.log(res.data, 1234);
					this.classify = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},



        // // 获取二级分类
        // getChildrenCate() {
        //     this.$http.getChildrenCate(
        //         {
        //             pid: 12,
        //             type: 2
        //         }
        //     ).then(res => {
        //         if (res.code == 1) {
        //             console.log(res.data, '二级分类');
        //             this.house_classify = res.data
        //             this.$http.getChildrenCate(
        //                 {
        //                     pid: 3,
        //                     type: 2
        //                 }
        //             ).then(res => {
        //                 if (res.code == 1) {
        //                     console.log(res.data, '二级分类');
        //                     this.car_classify = res.data
        //                     this.classify = this.house_classify.concat(this.car_classify)
        //                     console.log(this.classify, 1111);
        //                 }
        //             })
        //         }
        //     })
        // },

        // 获取经纪人列表
        getBorkerList() {
            this.$http.getBorkerList({
                // index_cate_id: this.pid,
                is_hot: 1,
                pageSize: 10
            }).then(res => {
                if (res.code == 1) {
                    this.borkerList = res.data.data
                    console.log(this.borkerList, 'this.borkerLis');
                }
            })
        },


        // 轮播图切换事件  暂时不写
        bannerArrChange(item) {
            console.log(item);
            let ads_type = item.ads_type;
            let bind_information_id = item.bind_information_id;
            let href = item.href;
            if (bind_information_id != 0) {
        if (item.url == 1) {
          this.$router.push({
            path: "/particulars",
            query: {
              id: bind_information_id,
              url: item.url,
            },
          });
        } else if (item.url == 2) {
          this.$router.push({
            path: "/particulars",
            query: {
              id: bind_information_id,
              url: item.url,
            },
          });
        } else {
          this.$router.push({
            // 普通页
            path: "/details",
            query: {
              id: item.bind_information_id,
            },
          });
        }
      } else {
        window.open('https://' +  href);
      }
        },
        // 获取用户信息
        getUserInfo() {
            this.$http.userInfo().then(res => {
                if (res.code == 1) {
                    this.vip = res.data.vip;
                }  else if (res.code == -201) {
					
				}else {
                    this.$message.info(res.msg);
                }
            })
        },
        // 获取轮播图
        getAds(type, typeid) {
            console.log(type, '快捷键了')
            this.$http.getAds({
                typeid,
            }).then(res => {
                if (res.code == 1) {
                    switch (type) {
                        case 1:
                            this.bannerArr1 = res.data;
                            break;
                        case 2:
                            this.bannerArr2 = res.data;
                            break;
                        case 3:
                            this.bannerArr3 = res.data;
                            break;
                        case 4:
                            this.bannerArr4 = res.data;
                            break;
                        case 5:
                            this.bannerArr5 = res.data;
                            break;
                        case 6:
                            this.bannerArr6 = res.data;
                            break;
                        case 7:
                            this.bannerArr7 = res.data;
                            break;
                    }
                } else {
                    this.$message.info(res.msg);
                }
            })
        },
        // 	去热门推荐
        toHotRecommand() {
            // let types = 1;
            this.$router.push({
                path: '/hotRecommand',
                // query:{
                //     pages:types,
                // }
            });
            // window.open('/hotRecommand')
            // let routeUrl=this.$router.resolve({
            // 	path: '/home/hotRecommand'
            // })
            // window.open(routeUrl.href,'_blank')
        },
        // 获取热门推荐
        getRecommend() {
            this.$http.getRecommend({
                pageNo: 1,
                pageSize: 24,
                is_index: 1,
                type: 1,
            }).then(res => {
                if (res.code == 1) {
                    this.recommendList = res.data.data;
                    this.$forceUpdate();
                } else {
                    this.$message.error(res.data.msg);
                }
                // console.log(res);
            }).catch((v) => {
                console.log(v);
            });
        },
        // 获取所有喜欢的
        getAllLike() {
            let data = {
                pageNo: this.currentPage,
                pageSize: this.pageSize,
                is_index: 1,
                type: 2,
            };
            this.loadingValue = true;
            this.$http.getRecommend(data).then(res => {
                this.loadingValue = false;
                window.scrollTo(0, 0)
                console.log(res);
                if (res.code == 1) {
                    this.total = res.data.total;
                    this.likeList = res.data.data;
                } else {
                    this.$message.info(res.msg);
                }
            }).catch(() => {
                this.loadingValue = false;
            })
        },

        currentChangeClick(currentPage) {
            this.currentPage = currentPage;
            this.getAllLike();

        },
        sizeChangeClick(pageSize) {
            this.pageSize = pageSize;
            this.getAllLike();
        },
    },

}
</script>

<style lang="less" scoped="scoped">
.big_img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.all_container {
    background: #F6F7F9;
}

.container {
    width: 100%;
    // height: 446px;
    margin: 0 auto;
    padding-bottom: 64px;
}

.changeClassify {
    display: flex;
    // justify-content: space-between;
    // width: 84px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 32px 0 0 32px;

    .title {
        color: #F39800;
    }
}

.carousel {
    margin-top: 36px;
    display: flex;

    .carousel_left {
        width: 232px;
    }

    .carousel_right {
        width: 100%;
        margin: 0 auto;
    }
}

.main_body {

    // 经纪人分类
    .info {
        // padding-top: 88px;
        background: #F6F7F9;
        padding: 0 36px;


        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;

            .border {
                width: 40px;
                height: 4px;
                background: rgba(243, 152, 0);
                border-radius: 2px;
                margin-top: 8px;
            }
        }

        .broker_box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .info_box {
                position: relative;
                display: flex;
                justify-content: space-between;
                margin-top: 56px;
                min-width: 890px;
                max-width: 1080px;
                height: 380px;
                background-color: #EFEFEF;

                .broker_img {
                    position: relative;
                    width: 400px;
                    height: 100%;
                    // background-image: url('../../../assets/particulars_icons/order.png');
                    background-size: cover;

                    .broker_img_m {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #000000;
                        opacity: 0.3;
                    }

                    .border_info {
                        position: absolute;
                        top: 142px;
                        left: 30px;
                        // z-index: 888;

                        .border_info_name {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #FFFFFF;
                            text-align: left;
                        }

                        .border {
                            margin-top: 24px;
                            margin-bottom: 32px;
                            background-color: #F39800;
                            height: 4px;
                            width: 80px;
                        }

                        .border_info_content {
                            width: 202px;
                            height: 115px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            line-height: 24px;
                            color: #FFFFFF;
                            text-align: left;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                        }
                    }
                }

                .talent_agency {
                    position: absolute;
                    display: flex;
                    padding: 56px 0 88px 48px;
                    width: 620px;
                    height: 316px;
                    background-color: #fff;
                    right: 0;
                    top: 32px;
                    box-sizing: border-box;

                    .left {
                        img {
                            width: 140px;
                            height: 140px;
                            border-radius: 50%;
                            background-color: pink;
                        }
                    }

                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin-left: 32px;
                        text-align: left;

                        .name {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                        }

                        .job {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #8D5710;
                        }

                        .num {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #888888;
                        }

                        .address {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;

                            img {
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }
                }

                .btn {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 240px;
                    height: 48px;
                    background: #F39800;
                    border-radius: 4px;
                    color: #fff;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 48px;

                    &:hover {
                        cursor: pointer;
                    }
                }



            }

        }



        .classify {
            position: relative;
            background-color: #fff;
            // height: 460px;
            width: 100%;
            box-sizing: border-box;

            .top {
                margin-top: 32px;
                padding-top: 71px;
                display: grid;
                justify-content: center;
                grid-template-columns: 16% 16% 16% 16% 16% 16%;

                .top_box {
                    position: relative;
                    margin-bottom: 48px;
                    margin-right: 10px;


                    .img {
                        position: absolute;
                        top: -30%;
                        left: 50%;
                        transform: translatex(-60%);
                        width: 70px;
                        height: 70px;

                        // background-color: pink;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .top_box_border {
                        // width: 280px;
                        height: 105px;
                        border-radius: 5px;
                        // background-color: pink;
                        border: 1px solid #FF8100;
                        border-bottom: 6px solid #FF8100;

                        .title {
                            margin-top: 50px;
                            font-size: 22px;
                            text-align: center;
                        }
                    }
                }

            }

            .bottom {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 48px;
                background-image: url('../../../assets/new_home_icons/bottom.png');
                background-repeat: no-repeat;
                background-size: cover;

            }


        }







        .company {
            width: 100%;

            .company_box {
                padding: 56px 54px;
                height: 328px;


                display: grid;
                background: #fff;
                justify-content: space-between;
                grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;

                img {
                    width: 180px;
                    height: 96px;
                }
            }



        }



    }

}



.hot_recommand {
    box-sizing: border-box;
    display: flex;
    margin-top: 16px;

    .hot_recommand_left {
        width: 232px;

        .two {
            margin-top: 16px;
        }
    }

    .hot_recommand_right {
        width: 968px;
        padding-left: 10px;

        .hot_img {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 20px;

            img {
                width: 205px;
                height: 52px;
            }
        }

        .all_house {
            display: flex;
            flex-wrap: wrap;
        }

        .check_more {
            margin-top: 24px;

            &:hover {
                cursor: pointer;
            }

            img {
                width: 120px;
                height: 36px;
            }
        }
    }
}



.vip {
    display: flex;
    margin-top: 40px;

    .vip_left {
        width: 232px;
    }

    .vip_right {
        width: 968px;


        .vip_img {

            img {
                width: 928px;
                height: 112px;
            }
        }

        .vip_carousel {
            margin-top: 32px;
            display: flex;
            justify-content: space-between;

            .vip_carousel_left {
                width: 474px;
            }

            .vip_carousel_right {
                width: 474px;
            }
        }

        .vip_big_carousel {
            margin-top: 10px;
            width: 958px;
        }
    }
}

.like {
    .like_img {
        margin: 40px 0 20px;

        img {
            width: 316px;
            height: 44px;
        }
    }

    .all_house {
        display: flex;
        flex-wrap: wrap;
    }
}


.carousel_left {
    width: 232px;

    /deep/ .el-carousel__arrow {
        top: 72%;
    }

    /deep/ .el-carousel__arrow--left,
    /deep/ .el-carousel__arrow--right {
        width: 24px;
        height: 24px;
    }
}

.carousel_left2 {
    width: 232px;

    /deep/ .el-carousel__arrow {}

    /deep/ .el-carousel__arrow--left,
    /deep/ .el-carousel__arrow--right {
        width: 24px;
        height: 24px;
    }
}


/deep/.el-carousel__indicators--horizontal {

    .el-carousel__indicator--horizontal button {
        width: 8px;
        height: 8px;
        background: #ffffff;
        border-radius: 50%;
        opacity: 0.5;
    }

    .el-carousel__indicator--horizontal.is-active button {
        width: 24px;
        height: 8px;
        background: #ffffff;
        opacity: 0.5;
        border-radius: 10px;
    }
}

.pagination {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
}

// 新增的样式
.loading {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0rpx;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingImg {
    width: 100rpx;
    height: 100rpx;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 11;
}
</style>
